import React from "react";
import {HashRouter, Route, Routes} from "react-router-dom";
import {about, getInTouch, mainBody, navBar, repos,} from "./editable-stuff/config.js";
import MainBody from "./components/home/MainBody";
import AboutMe from "./components/home/AboutMe";
import Project from "./components/home/Project";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
// import Skills from "./components/home/Skills";
// import { Blog } from "./components/blog/Blog";
// import BlogPost from "./components/blog/BlogPost";
import GetInTouch from "./components/home/GetInTouch.jsx";
// import Leadership from "./components/home/Leadership.jsx";
// import Experience from "./components/home/Experience";

const Home = React.forwardRef((props, ref) => {
    return (
        <>
            <MainBody
                gradient={mainBody.gradientColors}
                title={`${mainBody.firstName} ${mainBody.middleName} ${mainBody.lastName}`}
                message={mainBody.message}
                icons={mainBody.icons}
                ref={ref}
            />
            {about.show && (
                <AboutMe
                    heading={about.heading}
                    message={about.message}
                    link={about.imageLink}
                    imgSize={about.imageSize}
                    resume={about.resume}
                />
            )}
            {/*{*/}
            {/*  experiences.show && (*/}
            {/*    <Experience experiences={experiences}/>*/}
            {/*  )*/}
            {/*}*/}
            {repos.show && (
                <Project
                    heading={repos.heading}
                    username={repos.gitHubUsername}
                    length={repos.reposLength}
                    specfic={repos.specificRepos}
                />
            )}
            {/*{leadership.show && (*/}
            {/*  <Leadership*/}
            {/*    heading={leadership.heading}*/}
            {/*    message={leadership.message}*/}
            {/*    img={leadership.images}*/}
            {/*    imageSize={leadership.imageSize}*/}
            {/*  />*/}
            {/*)}*/}
            {/*{skills.show && (*/}
            {/*  <Skills*/}
            {/*    heading={skills.heading}*/}
            {/*    hardSkills={skills.hardSkills}*/}
            {/*    softSkills={skills.softSkills}*/}
            {/*  />*/}
            {/*)}*/}

        </>
    );
});

const App = () => {
    const titleRef = React.useRef();

    return (
        <HashRouter basename={process.env.PUBLIC_URL + "/"}>
            {navBar.show && <Navbar ref={titleRef}/>}
            <Routes>
                <Route path="/" exact element={<Home ref={titleRef}/>}/>
                <Route path="/grafana" element={<Grafana/>}/>
                <Route path="/kafka-ui" element={<KafkaUI/>}/>
                <Route path="/prometheus" element={<Prometheus/>}/>
                <Route path="/motioneye" element={<Motioneye/>}/>
            </Routes>
            {/* {false && }
      {false && <Route path="/blog/:id" component={BlogPost} />} */}
            <Footer>
                {getInTouch.show && (
                    <GetInTouch
                        heading={getInTouch.heading}
                        message={getInTouch.message}
                        email={getInTouch.email}
                    />
                )}
            </Footer>
        </HashRouter>
    );
};

function KafkaUI() {
    window.location.replace('http://46.138.250.238:9100');
    return null;
}

function Grafana() {
    window.location.replace('http://46.138.250.238:3136');
    return null;
}

function Prometheus() {
    window.location.replace('http://46.138.250.238:9090/graph');
    return null;
}

function Motioneye() {
    window.location.replace('http://46.138.250.238:6969');
    return null;
}

export default App;
